// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'My Game Engine',
		category: 'Custom Game Engine',
		img: require('@/assets/images/gameEngine_hero.gif'),
		link: '/projects/mygameengine',
	},
	{
		id: 2,
		title: 'LayOff',
		category: 'Multiplayer Game',
		img: require('@/assets/images/layOff.gif'),
		link: '/projects/layoffproject',  
	},
	{
		id: 3,
		title: 'LayOff Website & Analytics',
		category: 'Web Application',
		img: require('@/assets/images/layOffAnalytics.gif'),
		link: '/projects/layoffwebsite', 
	},
	{
		id: 4,
		title: 'StickDots',
		category: 'Multiplayer Game',
		img: require('@/assets/images/stickdots.gif'),
		link: '/projects/stickdots'
	},
	{
		id: 5,
		title: 'Rogue Protocol',
		category: 'Single Player Game',
		img: require('@/assets/images/rogueProtocol.gif'),
		link: '/projects/rogueprotocol'
	},
	{
		id: 6,
		title: 'RoboDash',
		category: 'Single Player Game',
		img: require('@/assets/images/roboDash.gif'),
		link: '/projects/robodash'
	},
];

export default projects;
