export const socialLinks = [
	{
		id: 1,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/alexbuzmion/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/AlexBuzmion',
	},
	{
		id: 3,
		name: 'Email',
		icon: 'mail',
		url: 'mailto:alex.buzmionii@gmail.com',
	},
];
